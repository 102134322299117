import Related from '../../../../../../../models/related/Related';
import { responseToMatchModel } from '../../matches/helpers/matches-container-helper';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import FootballSingleEventWidgetModel from '../../../widgets-v2/football-single-event/models/football-single-event-widget.model';
import LivescoreWidgetModel from '../../../../blocks/widgets/livescore/models/livescore-widget.model';
import StandingsWidgetV2Model from '../../../widgets-v2/football-standings/models/standings-widget-v2.model';
import FootballTeamFormWidgetModel from '../../../widgets-v2/football-team-form/models/football-team-form.model';
import KnockoutWidgetV2Model from '../../../widgets-v2/football-knockout-scheme/models/knockout-widget-v2.model';
import FootballTeamH2HWidgetModel from '../../../widgets-v2/football-team-h2h/models/football-team-h2h.model';
import FootballTeamSquadWidgetModel from '../../../widgets-v2/football-team-squad/models/football-team-squad.model';
import TeamProgrammeWidgetModel from '../../../widgets-v2/football-team-programme/models/team-programme-widget.model';
import FootballPlayerH2HWidgetModel from '../../../widgets-v2/football-player-h2h/models/football-player-h2h.model';
import FootballPlayerWidgetModel from '../../../widgets-v2/football-player/models/football-player-widget.model';
import FootballTopScorersModel from '../../../widgets-v2/football-top-scorers/models/football-top-scorers.model';
import TournamentProgrammeWidgetModel from '../../../widgets-v2/football-tournament-programme/models/football-tournament-programme.model';
import FootballMostDecoratedPlayersModel from '../../../widgets-v2/football-most-decorated-players/models/football-most-decorated-players.model';
import FootballTeamWidgetModel from '../../../widgets-v2/football-team/models/football-team-widget.model';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import GameWidgetModel from '../../../../blocks/widgets/basketball-single-event/models/game-widget.model';
import BasketballStandingsModel from '../../../../blocks/widgets/basketball-standings/models/basketball-standings.model';
import { relatedConstants } from '../../../../../../../constants/related.constants';
import GameModel from '../../../../../../../models/v2/game/game.model';
import { responseToGameModel } from '../../../../partials/games/helpers/games-container-helper';
import { responseToPlayerModel } from '../player-select/player-select-component.helper';
import { responseToTeamModel } from '../team-select/team-select-component.helper';
import { responseToTournamentModel } from '../tournament-select/tournament-select-component.helper';
import { responseToSeasonModel } from '../season-select/season-select-component.helper';
import { responseToBasketballSeasonModel } from '../../../../partials/season-select/helpers/basketball-season-select.helper';
import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import { responseToCompetitionModel } from '../../../../partials/competition-select/helpers/competition-select.helper';
import TennisSingleEventModel from '../../../widgets-v2/tennis-single-event/models/tennis-single-event.model';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { ContentTypes } from '../../../../../../../constants/content-types';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import FootballLineupsModel from '../../../widgets-v2/football-lineups/models/football-lineups.model';
import {
	teamH2HStatsOptions,
	teamMatchStatsOptions,
	teamSquadStatsOptions,
} from '../../../widgets-v2/football-team/constants/football-team.constants';
import TennisRankingModel from '../../../widgets-v2/tennis-ranking/models/tennis-ranking.model';
import TennisPlayoffModel from '../../../widgets-v2/tennis-playoff/models/tennis-playoff.model';
import BasketballTournamentProgrammeWidgetModel from '../../../widgets-v2/basketball-tournament-programme/models/basketball-tournament-programme.model';
import BasketballStageModel from '../../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import BasketballSeasonModel from '../../../../../../../models/v2/basketball-season/entity/basketball-season.model';
import BasketballTeamProgrammeWidgetModel from '../../../widgets-v2/basketball-team-programme/models/basketball-team-programme.model';
import FootballMatchesH2hModel from '../../../widgets-v2/football-matches-h2h/models/football-matches-h2h.model';
import TennisAthleteProgrammeModel from '../../../widgets-v2/tennis-athlete-programme/models/tennis-athlete-programme.model';
import TennisCompetitionSeasonModel from '../../../../../../../models/v2/tennis-competition-season/entity/tennis-competition-season.model';
import TennisTournamentModel from '../../../../../../../models/v2/tennis-tournament/tennis-tournament.model';
import TennisTournamentProgrammeModel from '../../../widgets-v2/tennis-tournament-programme/models/tennis-tournament-programme.model';
import FootballTeamH2hMatchWidgetModel from '../../../widgets-v2/football-team-h2h/models/football-team-match-stats.model';
import MatchModelV2 from '../../../widgets-v2/football-single-event/models/MatchModelV2.model';
import { responseToMatchModelV2 } from '../../matches/matches-v2/matches-container-v2.helper';
import BlockModel from '../../../../models/block.model';
import OddsModelV2 from '../../../widgets-v2/odds/models/odds-widget.model';
import { BASKETBALL_STANDINGS_HEADER_DEFAULT_OPTION } from '../../header-default-option-input/helper';
import SportTeamModel from '../../../../partials/sports-team-select/models/sport-team.model';
import StakesModel from '../../../widgets-v2/stakes/models/stakes-widget.model';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { store } from '../../../../../../../store/store';
import { EventStatuses } from '../../../../../../../constants/event.types';
import RoundWidgetModel from '../../../widgets-v2/football-round/models/football-round.model';
import BasketballRoundModel from '../../../../../../../models/v2/round/entity/basketball-round.model';
import IceHockeyStandingsModel from '../../../widgets-v2/ice-hockey-standings/models/ice-hockey-standings.model';

export type blockTypes =
	| FootballSingleEventWidgetModel
	| LivescoreWidgetModel
	| StandingsWidgetV2Model
	| FootballTeamFormWidgetModel
	| KnockoutWidgetV2Model
	| FootballTeamH2HWidgetModel
	| FootballTeamSquadWidgetModel
	| TeamProgrammeWidgetModel
	| FootballPlayerH2HWidgetModel
	| FootballPlayerWidgetModel
	| FootballTopScorersModel
	| TournamentProgrammeWidgetModel
	| FootballMostDecoratedPlayersModel
	| FootballTeamWidgetModel
	| GameWidgetModel
	| BasketballStandingsModel
	| TennisSingleEventModel
	| FootballLineupsModel
	| FootballMatchesH2hModel
	| TennisAthleteProgrammeModel
	| TennisTournamentProgrammeModel
	| FootballTeamH2hMatchWidgetModel
	| OddsModelV2;

export type availableWidgetTypes =
	| WidgetsV2Ids.widgetFootballLivescore
	| WidgetsV2Ids.widgetFootballPlayer
	| WidgetsV2Ids.widgetFootballTeam
	| WidgetsV2Ids.widgetFootballPlayerH2H
	| WidgetsV2Ids.widgetFootballKnockout
	| WidgetsV2Ids.widgetFootballSingleEvent
	| WidgetsV2Ids.widgetFootballTournamentProgramme
	| WidgetsV2Ids.widgetFootballTeamSquad
	| WidgetsV2Ids.widgetFootballStandings
	| WidgetsV2Ids.widgetFootballTeamProgramme
	| WidgetsV2Ids.widgetFootballTeamH2H
	| WidgetsV2Ids.widgetFootballTeamH2HMatch
	| WidgetsV2Ids.widgetFootballMostDecoratedPlayers
	| WidgetsV2Ids.widgetFootballTeamForm
	| WidgetsV2Ids.widgetFootballTopScorers
	| WidgetsV2Ids.widgetBasketballSingleEvent
	| WidgetsV2Ids.widgetBasketballLivescore
	| WidgetsV2Ids.widgetBasketballStandings
	| WidgetsV2Ids.widgetTennisSingleEvent
	| WidgetsV2Ids.widgetTennisLivescore
	| WidgetsV2Ids.widgetTennisRanking
	| WidgetsV2Ids.widgetTennisPlayoff
	| WidgetsV2Ids.widgetFootballLineups
	| WidgetsV2Ids.widgetBasketballTournamentProgramme
	| WidgetsV2Ids.widgetBasketballTeamProgramme
	| WidgetsV2Ids.widgetFootballMatchesH2H
	| WidgetsV2Ids.widgetTennisAthleteProgramme
	| WidgetsV2Ids.widgetTennisTournamentProgramme
	| WidgetsV2Ids.widgetFootballOdds
	| WidgetsV2Ids.widgetFootballStakes
	| WidgetsV2Ids.widgetFootballSingleRound
	| WidgetsV2Ids.widgetIceHockeyStandings;

export const getModelByWidgetType = (contentType: string, block: BlockModel) => {
	switch (contentType) {
		case WidgetsV2Ids.widgetFootballSingleEvent:
			return FootballSingleEventWidgetModel;
		case WidgetsV2Ids.widgetFootballLivescore:
			return LivescoreWidgetModel;
		case WidgetsV2Ids.widgetFootballStandings:
			return StandingsWidgetV2Model;
		case WidgetsV2Ids.widgetFootballKnockout:
			return KnockoutWidgetV2Model;
		case WidgetsV2Ids.widgetFootballTeamProgramme:
			return TeamProgrammeWidgetModel;
		case WidgetsV2Ids.widgetFootballPlayer:
			return FootballPlayerWidgetModel;
		case WidgetsV2Ids.widgetFootballTeamSquad:
			return FootballTeamSquadWidgetModel;
		case WidgetsV2Ids.widgetFootballTeamForm:
			return FootballTeamFormWidgetModel;
		case WidgetsV2Ids.widgetFootballTeamH2H:
			return FootballTeamH2HWidgetModel;
		case WidgetsV2Ids.widgetFootballTeamH2HMatch:
			return FootballTeamH2hMatchWidgetModel;
		case WidgetsV2Ids.widgetFootballPlayerH2H:
			return FootballPlayerH2HWidgetModel;
		case WidgetsV2Ids.widgetFootballTournamentProgramme:
			return TournamentProgrammeWidgetModel;
		case WidgetsV2Ids.widgetFootballTopScorers:
			return FootballTopScorersModel;
		case WidgetsV2Ids.widgetFootballTeam:
			return FootballTeamWidgetModel;
		case WidgetsV2Ids.widgetFootballMostDecoratedPlayers:
			return FootballMostDecoratedPlayersModel;
		case WidgetsV2Ids.widgetBasketballSingleEvent:
			return GameWidgetModel;
		case WidgetsV2Ids.widgetBasketballLivescore:
			return LivescoreWidgetModel;
		case WidgetsV2Ids.widgetBasketballStandings:
			return BasketballStandingsModel;
		case WidgetsV2Ids.widgetTennisSingleEvent:
			return TennisSingleEventModel;
		case WidgetsV2Ids.widgetTennisLivescore:
			return LivescoreWidgetModel;
		case WidgetsV2Ids.widgetTennisRanking:
			return TennisRankingModel;
		case WidgetsV2Ids.widgetTennisPlayoff:
			return TennisPlayoffModel;
		case WidgetsV2Ids.widgetFootballLineups:
			return FootballLineupsModel;
		case WidgetsV2Ids.widgetBasketballTournamentProgramme:
			return BasketballTournamentProgrammeWidgetModel;
		case WidgetsV2Ids.widgetBasketballTeamProgramme:
			return BasketballTeamProgrammeWidgetModel;
		case WidgetsV2Ids.widgetFootballMatchesH2H:
			return FootballMatchesH2hModel;
		case WidgetsV2Ids.widgetTennisAthleteProgramme:
			return TennisAthleteProgrammeModel;
		case WidgetsV2Ids.widgetTennisTournamentProgramme:
			return TennisTournamentProgrammeModel;
		case WidgetsV2Ids.widgetFootballOdds:
			return OddsModelV2;
		case WidgetsV2Ids.widgetFootballStakes:
			return StakesModel;
		case WidgetsV2Ids.widgetFootballSingleRound:
			return RoundWidgetModel;
		case WidgetsV2Ids.widgetIceHockeyLivescore:
			return LivescoreWidgetModel;
		case WidgetsV2Ids.widgetIceHockeySingleEvent:
			return GameWidgetModel;
		case WidgetsV2Ids.widgetIceHockeyStandings:
			return IceHockeyStandingsModel;
		default:
			return null;
	}
};

export const getBuilderByWidgetType = (contentType: string, contentData: any, block: BlockModel) => {
	switch (contentType) {
		case WidgetsV2Ids.widgetFootballSingleEvent:
			return buildFootballSingleEvent(contentData);
		case WidgetsV2Ids.widgetFootballLivescore:
			return buildFootballLivescore();
		case WidgetsV2Ids.widgetFootballStandings:
			return buildFootballStandings(contentData);
		case WidgetsV2Ids.widgetFootballKnockout:
			return buildFootballKnockout(contentData);
		case WidgetsV2Ids.widgetFootballTeamProgramme:
			return buildFootballTeamProgramme(contentData);
		case WidgetsV2Ids.widgetFootballPlayer:
			return buildFootballPlayer(contentData);
		case WidgetsV2Ids.widgetFootballTeamSquad:
			return buildFootballTeamSquad(contentData);
		case WidgetsV2Ids.widgetFootballTeamForm:
			return buildFootballTeamForm(contentData);
		case WidgetsV2Ids.widgetFootballTeamH2H:
			return buildFootballTeamH2H(contentData);
		case WidgetsV2Ids.widgetFootballTeamH2HMatch:
			return buildFootballTeamH2hMatch(contentData);
		case WidgetsV2Ids.widgetFootballPlayerH2H:
			return buildFootballPlayerH2H(contentData);
		case WidgetsV2Ids.widgetFootballTournamentProgramme:
			return buildFootballTournamentProgramme(contentData);
		case WidgetsV2Ids.widgetFootballTopScorers:
			return buildFootballTopScorers(contentData);
		case WidgetsV2Ids.widgetFootballTeam:
			return buildFootballTeam(contentData);
		case WidgetsV2Ids.widgetFootballMostDecoratedPlayers:
			return buildFootballMostDecoratedPlayers(contentData);
		case WidgetsV2Ids.widgetBasketballSingleEvent:
			return buildBasketballSingleEvent(contentData);
		case WidgetsV2Ids.widgetBasketballLivescore:
			return buildBasketballivescore();
		case WidgetsV2Ids.widgetBasketballStandings:
			return buildBasketballStandings(contentData);
		case WidgetsV2Ids.widgetTennisSingleEvent:
			return buildTennisSingleEvent(contentData);
		case WidgetsV2Ids.widgetTennisLivescore:
			return buildTennisLivescore();
		case WidgetsV2Ids.widgetTennisRanking:
			return buildTennisRanking();
		case WidgetsV2Ids.widgetTennisPlayoff:
			return buildTennisPlayoff();
		case WidgetsV2Ids.widgetFootballLineups:
			return buildFootballLineups(contentData);
		case WidgetsV2Ids.widgetBasketballTournamentProgramme:
			return buildBasketballTournamentProgramme(contentData);
		case WidgetsV2Ids.widgetBasketballTeamProgramme:
			return buildBasketballTeamProgramme(contentData);
		case WidgetsV2Ids.widgetFootballMatchesH2H:
			return buildFootballMatchesH2H(contentData);
		case WidgetsV2Ids.widgetTennisAthleteProgramme:
			return buildTennisAthleteProgramme(contentData);
		case WidgetsV2Ids.widgetTennisTournamentProgramme:
			return buildTennisTournamentProgramme(contentData);
		case WidgetsV2Ids.widgetFootballOdds:
			return buildOdds(contentData);
		case WidgetsV2Ids.widgetFootballStakes:
			return buildStakes(contentData);
		case WidgetsV2Ids.widgetFootballSingleRound:
			return buildFootballSingleRound(contentData);
		case WidgetsV2Ids.widgetIceHockeyLivescore:
			return buildIceHockeyLivescore();
		case WidgetsV2Ids.widgetIceHockeySingleEvent:
			return buildIceHockeySingleEvent(contentData);
		case WidgetsV2Ids.widgetIceHockeyStandings:
			return buildIceHockeyStandings(contentData);
		default:
			return null;
	}
};

export const checkIfOddsAutoCheckEnabled = () => {
	const features = store.getState().project.currentProject.features ? store.getState().project.currentProject.features : [];

	if (featuresService.checkFeatureIsSet(FeatureTypes.AUTO_CHECK_DISPLAY_ODDS)) {
		return featuresService.checkEnabled([FeatureTypes.AUTO_CHECK_DISPLAY_ODDS], features);
	}

	return true;
};

export const buildFootballSingleEvent = (contentData: any) => {
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	const data =
		contentData && contentData.related ? prefillFootballSingleEventMatchFromRelated(contentData.related.matches) : ({} as MatchModel);
	if (contentData) {
		return FootballSingleEventWidgetModel.builder()
			.withTeams(data.homeTeam && data.homeTeam.id && data.awayTeam && data.awayTeam.id ? [data.homeTeam, data.awayTeam] : [])
			.withMatch(prefillFootballSingleEventMatchFromRelated(contentData.related && contentData.related.matches))
			.withFansUnitedEnabled(false)
			.withFansUnitedExpanded(false)
			.withDisplayOdds(data && data.id && data.eventStatus && data.eventStatus.code !== 'finished' && isAutoCheckEnabled)
			.build();
	}
};

export const buildFootballLineups = (contentData: any) => {
	const data =
		contentData && contentData.related ? prefillFootballSingleEventMatchFromRelated(contentData.related.matches) : ({} as MatchModel);
	const teams = contentData ? remapTeamsFromRelated(contentData, relatedConstants.providers.football) : ([] as TeamModel[]);
	const teamsData = data.homeTeam && data.homeTeam.id && data.awayTeam && data.awayTeam.id ? [data.homeTeam, data.awayTeam] : teams;
	if (contentData) {
		return FootballLineupsModel.builder()
			.withTeams(teamsData)
			.withMatch(prefillFootballSingleEventMatchFromRelated(contentData.related && contentData.related.matches))
			.withCoachParameters({})
			.withFirstTeamParameters({})
			.withSubstitutesParameters({})
			.build();
	}
};

export const buildOdds = (contentData: any) => {
	if (contentData) {
		return OddsModelV2.builder()
			.withTeam(remapTeamFromRelated(contentData, relatedConstants.providers.football))
			.withMatch(prefillFootballSingleEventMatchFromRelated(contentData.related && contentData.related.matches))
			.withBookmakers(null)
			.withMainBookmaker(null)
			.withMarket({ name: '1x2', value: '1x2' })
			.withMarketValueType({ name: 'DECIMAL', value: 'DECIMAL' })
			.build();
	}
};

export const buildStakes = (contentData: any) => {
	if (contentData) {
		return StakesModel.builder()
			.withTeam(remapTeamFromRelated(contentData, relatedConstants.providers.football))
			.withMatch(prefillFootballSingleEventMatchFromRelated(contentData.related && contentData.related.matches))
			.withAmount(10)
			.withFixedAmount(true)
			.withBookmakers(null)
			.build();
	}
};

export const buildFootballLivescore = () => {
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	return LivescoreWidgetModel.builder().withDisplayOdds(isAutoCheckEnabled).build();
};

export const buildFootballStandings = (contentData: any) => {
	if (contentData) {
		return StandingsWidgetV2Model.builder()
			.withTournament(remapTournamentFromRelated(contentData, relatedConstants.providers.football))
			.withStage({} as StageModel)
			.withGroup([])
			.withSeason(remapSeasonFromRelated(contentData, relatedConstants.providers.football))
			.withOffset('0')
			.build();
	}
};

export const buildFootballKnockout = (contentData: any) => {
	if (contentData) {
		return KnockoutWidgetV2Model.builder()
			.withTournament(remapTournamentFromRelated(contentData, relatedConstants.providers.football))
			.withStage({} as StageModel)
			.withSeason(remapSeasonFromRelated(contentData, relatedConstants.providers.football))
			.build();
	}
};

export const buildFootballPlayer = (contentData: any) => {
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	if (contentData) {
		return FootballPlayerWidgetModel.builder()
			.withPlayer(remapPlayerFromRelated(contentData, relatedConstants.providers.football, SportTypes.FOOTBALL))
			.withTournamentSeason(remapTournamentSeasonFromRelated(contentData, relatedConstants.providers.football))
			.withStage({} as StageModel)
			.withFansUnitedEnabled(false)
			.withFansUnitedExpanded(false)
			.withDisplayOdds(isAutoCheckEnabled)
			.build();
	}
};

export const buildFootballTeam = (contentData: any) => {
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	if (contentData) {
		return FootballTeamWidgetModel.builder()
			.withTeam(remapTeamFromRelated(contentData, relatedConstants.providers.football))
			.withTournamentSeason(remapTournamentSeasonFromRelated(contentData, relatedConstants.providers.football))
			.withFansUnitedEnabled(false)
			.withFansUnitedExpanded(false)
			.withDisplayOdds(isAutoCheckEnabled)
			.build();
	}
};

export const buildFootballTeamProgramme = (contentData: any) => {
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	if (contentData) {
		return TeamProgrammeWidgetModel.builder()
			.withTournament(remapTournamentFromRelated(contentData, relatedConstants.providers.football))
			.withStage({} as StageModel)
			.withTeam(remapTeamFromRelated(contentData, relatedConstants.providers.football))
			.withSeason(remapSeasonFromRelated(contentData, relatedConstants.providers.football))
			.withDisplayOdds(isAutoCheckEnabled)
			.build();
	}
};

export const buildFootballTournamentProgramme = (contentData: any) => {
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	if (contentData) {
		return TournamentProgrammeWidgetModel.builder()
			.withTournament(remapTournamentFromRelated(contentData, relatedConstants.providers.football))
			.withStage({} as StageModel)
			.withSeason(remapSeasonFromRelated(contentData, relatedConstants.providers.football))
			.withRound([])
			.withDisplayOdds(isAutoCheckEnabled)
			.build();
	}
};

export const buildFootballTeamSquad = (contentData: any) => {
	if (contentData) {
		return FootballTeamSquadWidgetModel.builder()
			.withTeam(remapTeamFromRelated(contentData, relatedConstants.providers.football))
			.withTournamentSeason(remapTournamentSeasonFromRelated(contentData, relatedConstants.providers.football))
			.withElements(teamSquadStatsOptions)
			.build();
	}
};

export const buildFootballTeamForm = (contentData: any) => {
	if (contentData) {
		return FootballTeamFormWidgetModel.builder()
			.withTeam(remapTeamFromRelated(contentData, relatedConstants.providers.football))
			.withTournament(remapTournamentFromRelated(contentData, relatedConstants.providers.football))
			.withSeason(remapSeasonFromRelated(contentData, relatedConstants.providers.football))
			.build();
	}
};

export const buildFootballTopScorers = (contentData: any) => {
	const teamData = contentData ? remapTeamsFromRelated(contentData, relatedConstants.providers.football) : ([] as TeamModel[]);
	const filter = teamData && teamData.length > 0;
	const players = contentData ? remapPlayersFromRelated(contentData, relatedConstants.providers.football) : ([] as PlayerModel[]);
	const teams = [teamData[0]];
	if (contentData) {
		return FootballTopScorersModel.builder()
			.withTeams(filter ? teams : ([] as TeamModel[]))
			.withTournament(remapTournamentFromRelated(contentData, relatedConstants.providers.football))
			.withSeason(remapSeasonFromRelated(contentData, relatedConstants.providers.football))
			.withTopScorers(filter && teamData ? players : ([] as PlayerModel[]))
			.withFilter(filter)
			.build();
	}
};

export const buildFootballMostDecoratedPlayers = (contentData: any) => {
	const tournamentData = contentData
		? remapTournamentFromRelated(contentData, relatedConstants.providers.football)
		: ({} as TournamentModel);
	const seasonData = contentData ? remapSeasonFromRelated(contentData, relatedConstants.providers.football) : ({} as SeasonModel);
	const players = contentData ? remapPlayersFromRelated(contentData, relatedConstants.providers.football) : ([] as PlayerModel[]);
	if (contentData) {
		return FootballMostDecoratedPlayersModel.builder()
			.withTournament(tournamentData)
			.withSeason(seasonData)
			.withHighlightPlayers(tournamentData && seasonData && players)
			.build();
	}
};

export const buildFootballTeamH2H = (contentData: any) => {
	const seasonData = contentData
		? remapTournamentSeasonFromRelated(contentData, relatedConstants.providers.football)
		: ({} as EntitySeasonsSelectModel);
	const teams = contentData ? remapTeamsFromRelated(contentData, relatedConstants.providers.football) : ([] as TeamModel[]);

	if (contentData) {
		return FootballTeamH2HWidgetModel.builder()
			.withTeamOne(teams[0])
			.withTeamTwo(teams[1])
			.withTeamOneSeason(seasonData)
			.withTeamTwoSeason(seasonData)
			.withStats(teamH2HStatsOptions)
			.build();
	}
};

export const buildFootballTeamH2hMatch = (contentData: any) => {
	const matchData =
		contentData && contentData.related ? prefillFootballSingleEventMatchFromRelated(contentData.related.matches) : ({} as MatchModel);
	const teams = contentData ? remapTeamsFromRelated(contentData, relatedConstants.providers.football) : ([] as TeamModel[]);
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	if (contentData) {
		return FootballTeamH2hMatchWidgetModel.builder()
			.withTeamOne(matchData.id ? matchData.homeTeam : teams[0])
			.withTeamTwo(matchData.id ? matchData.awayTeam : teams[1])
			.withTeamOneSeason({} as EntitySeasonsSelectModel)
			.withTeamTwoSeason({} as EntitySeasonsSelectModel)
			.withMatch(prefillFootballSingleEventMatchFromRelated(contentData.related && contentData.related.matches))
			.withDisplayOdds(matchData && matchData.id && matchData.eventStatus && matchData.eventStatus.code !== 'finished' && isAutoCheckEnabled)
			.withStats(teamMatchStatsOptions)
			.build();
	}
};

export const buildFootballMatchesH2H = (contentData: any) => {
	const teams = contentData ? remapTeamsFromRelated(contentData, relatedConstants.providers.football) : ([] as TeamModel[]);
	if (contentData) {
		return FootballMatchesH2hModel.builder().withTeamOne(teams[0]).withTeamTwo(teams[1]).build();
	}
};

export const buildFootballPlayerH2H = (contentData: any) => {
	const seasonData = contentData
		? remapTournamentSeasonFromRelated(contentData, relatedConstants.providers.football)
		: ({} as EntitySeasonsSelectModel);
	const players = contentData ? remapPlayersFromRelated(contentData, relatedConstants.providers.football) : ([] as PlayerModel[]);
	if (contentData) {
		return FootballPlayerH2HWidgetModel.builder()
			.withPlayerOne(players[0])
			.withPlayerTwo(players[1])
			.withPlayerOneSeason(seasonData)
			.withPlayerTwoSeason(seasonData)
			.build();
	}
};

export const buildFootballSingleRound = (contentData: any) => {
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	if (contentData) {
		return RoundWidgetModel.builder()
			.withTournament(remapTournamentFromRelated(contentData, relatedConstants.providers.football))
			.withStage({} as BasketballStageModel)
			.withSeason(remapSeasonFromRelated(contentData, relatedConstants.providers.football))
			.withRound({} as BasketballRoundModel)
			.withDisplayOdds(isAutoCheckEnabled)
			.build();
	}
};

export const buildBasketballSingleEvent = (contentData: any) => {
	const data =
		contentData && contentData.related ? prefillGameFromRelated(contentData.related.matches, SportTypes.BASKETBALL) : ({} as GameModel);
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	if (contentData) {
		return GameWidgetModel.builder()
			.withTeams(
				data.results && data.results[0] && data.results[0].id && data.results[1] && data.results[1].id
					? [data.results[0], data.results[1]]
					: [],
			)
			.withGame(prefillGameFromRelated(contentData.related && contentData.related.matches, SportTypes.BASKETBALL))
			.withDisplayOdds(!!(data && data.id && data.statusType && data.statusType !== EventStatuses.FINISHED && isAutoCheckEnabled))
			.build();
	}
};

export const buildBasketballivescore = () => {
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	return LivescoreWidgetModel.builder().withDisplayOdds(isAutoCheckEnabled).build();
};

export const buildBasketballStandings = (contentData: any) => {
	if (contentData) {
		return BasketballStandingsModel.builder()
			.withTournament(remapCompetitionFromRelated(contentData, relatedConstants.providers.sports, SportTypes.BASKETBALL))
			.withHeaderDefaultOption(BASKETBALL_STANDINGS_HEADER_DEFAULT_OPTION)
			.build();
	}
};

export const buildBasketballTournamentProgramme = (contentData: any) => {
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	if (contentData) {
		return BasketballTournamentProgrammeWidgetModel.builder()
			.withComeptition(remapCompetitionFromRelated(contentData, relatedConstants.providers.sports, SportTypes.BASKETBALL))
			.withStage({} as BasketballStageModel)
			.withSeason(remapBasketballSeasonFromRelated(contentData, relatedConstants.providers.sports))
			.withRounds([])
			.withDisplayOdds(isAutoCheckEnabled)
			.build();
	}
};

export const buildBasketballTeamProgramme = (contentData: any) => {
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	if (contentData) {
		return BasketballTeamProgrammeWidgetModel.builder()
			.withTeam(remapTeamFromSportsApiRelated(contentData, relatedConstants.providers.sports, SportTypes.BASKETBALL))
			.withComeptition(remapCompetitionFromRelated(contentData, relatedConstants.providers.sports, SportTypes.BASKETBALL))
			.withStage({} as BasketballStageModel)
			.withSeason(remapBasketballSeasonFromRelated(contentData, relatedConstants.providers.sports))
			.withRounds([])
			.withDisplayOdds(isAutoCheckEnabled)
			.build();
	}
};

export const buildTennisSingleEvent = (contentData: any) => {
	const data =
		contentData && contentData.related ? prefillMatchFromRelated(contentData.related.matches, SportTypes.TENNIS) : ({} as GameModel);
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	if (contentData) {
		return TennisSingleEventModel.builder()
			.withPlayers(
				data.results && data.results[0] && data.results[0].id && data.results[1] && data.results[1].id
					? [data.results[0], data.results[1]]
					: [],
			)
			.withMatch(prefillMatchFromRelated(contentData.related && contentData.related.matches, SportTypes.TENNIS))
			.withDisplayOdds(!!(data && data.id && data.statusType && data.statusType !== EventStatuses.FINISHED && isAutoCheckEnabled))
			.build();
	}
};

export const buildTennisLivescore = () => {
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	return LivescoreWidgetModel.builder().withDisplayOdds(isAutoCheckEnabled).build();
};

export const buildTennisRanking = () => {
	return TennisRankingModel.builder().withOffset('0').build();
};

export const buildTennisPlayoff = () => {
	return TennisPlayoffModel.builder().build();
};

export const buildTennisAthleteProgramme = (contentData: any) => {
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	if (contentData) {
		return TennisAthleteProgrammeModel.builder()
			.withAthlete(remapTennisPlayerFromRelated(contentData, relatedConstants.providers.sports, SportTypes.TENNIS))
			.withCompetition(remapCompetitionFromRelated(contentData, relatedConstants.providers.sports, SportTypes.TENNIS))
			.withSeason({} as TennisCompetitionSeasonModel)
			.withTournament({} as TennisTournamentModel)
			.withRounds([])
			.withDisplayOdds(isAutoCheckEnabled)

			.build();
	}
};

export const buildTennisTournamentProgramme = (contentData: any) => {
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	if (contentData) {
		return TennisTournamentProgrammeModel.builder()
			.withCompetition(remapCompetitionFromRelated(contentData, relatedConstants.providers.sports, SportTypes.TENNIS))
			.withSeason({} as TennisCompetitionSeasonModel)
			.withTournament({} as TennisTournamentModel)
			.withRounds([])
			.withDisplayOdds(isAutoCheckEnabled)

			.build();
	}
};

export const buildIceHockeyLivescore = () => {
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	return LivescoreWidgetModel.builder().withDisplayOdds(isAutoCheckEnabled).build();
};

export const buildIceHockeySingleEvent = (contentData: any) => {
	const data =
		contentData && contentData.related ? prefillGameFromRelated(contentData.related.matches, SportTypes.ICE_HOCKEY) : ({} as GameModel);
	const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

	if (contentData) {
		return GameWidgetModel.builder()
			.withTeams(
				data.results && data.results[0] && data.results[0].id && data.results[1] && data.results[1].id
					? [data.results[0], data.results[1]]
					: [],
			)
			.withGame(prefillGameFromRelated(contentData.related && contentData.related.matches, SportTypes.ICE_HOCKEY))
			.withDisplayOdds(!!(data && data.id && data.statusType && data.statusType !== EventStatuses.FINISHED && isAutoCheckEnabled))
			.build();
	}
};

export const buildIceHockeyStandings = (contentData: any) => {
	if (contentData) {
		return IceHockeyStandingsModel.builder()
			.withTournament(remapCompetitionFromRelated(contentData, relatedConstants.providers.sports, SportTypes.ICE_HOCKEY))
			.withHeaderDefaultOption(BASKETBALL_STANDINGS_HEADER_DEFAULT_OPTION)
			.build();
	}
};

export const remapMatchForSingleEventFromRelated = (relatedMatch: Related, provider: string): MatchModel => {
	if (relatedMatch && relatedMatch.type === ContentTypes.MATCH && relatedMatch.provider === provider) {
		relatedMatch.data.isSelected = true;
		return responseToMatchModel(relatedMatch.data);
	}

	return {} as MatchModel;
};

export const remapMatchForSingleEventFromRelatedV2 = (relatedMatch: Related, provider: string): MatchModelV2 => {
	if (relatedMatch && relatedMatch.type === ContentTypes.MATCH && relatedMatch.provider === provider) {
		return responseToMatchModelV2(relatedMatch.data);
	}

	return {} as MatchModelV2;
};

export const remapGameFromRelated = (relatedMatch: Related, provider: string, sport: string): GameModel => {
	if (relatedMatch && relatedMatch.type === ContentTypes.GAME && relatedMatch.provider === provider && relatedMatch.data.sport === sport) {
		return responseToGameModel(relatedMatch.data);
	}

	return {} as GameModel;
};

export const remapMatchFromRelated = (relatedMatch: Related, provider: string, sport: string): GameModel => {
	if (relatedMatch && relatedMatch.type === ContentTypes.MATCH && relatedMatch.provider === provider && relatedMatch.data.sport === sport) {
		return responseToGameModel(relatedMatch.data);
	}

	return {} as GameModel;
};

export const remapTeamFromRelated = (relatedTeam: any, provider: string): TeamModel => {
	if (relatedTeam && relatedTeam.related && relatedTeam.related.sports) {
		const result = relatedTeam.related.sports.filter((entity: any) => entity.type === ContentTypes.TEAM && entity.provider === provider);
		return result[0] && result[0].data && responseToTeamModel(result[0].data);
	}

	return {} as TeamModel;
};

export const remapTeamsFromRelated = (relatedTeams: any, provider: string): TeamModel[] => {
	if (relatedTeams && relatedTeams.related && relatedTeams.related.sports) {
		const result = relatedTeams.related.sports.filter((entity: Related) => entity.type === ContentTypes.TEAM && entity.provider === provider);
		return result.map((team: any) => {
			return responseToTeamModel(team.data);
		});
	}

	return [] as TeamModel[];
};

export const remapPlayersFromRelated = (relatedPlayers: any, provider: string): PlayerModel[] => {
	if (relatedPlayers && relatedPlayers.related && relatedPlayers.related.sports) {
		const result = relatedPlayers.related.sports.filter(
			(entity: Related) => entity.type === ContentTypes.PLAYER && entity.provider === provider,
		);
		return result.map((player: any) => {
			return responseToPlayerModel(player.data);
		});
	}

	return [] as PlayerModel[];
};

export const remapPlayerFromRelated = (relatedPlayer: any, provider: string, sport: string): PlayerModel => {
	if (relatedPlayer && relatedPlayer.related && relatedPlayer.related.sports) {
		const result = relatedPlayer.related.sports.filter(
			(entity: Related) => entity.data && entity.type === ContentTypes.PLAYER && entity.provider === provider && entity.data.sport === sport,
		);
		return result[0] && result[0].data && responseToPlayerModel(result[0].data);
	}
	return {} as PlayerModel;
};

export const remapTennisPlayerFromRelated = (relatedPlayer: any, provider: string, sport: string): PlayerModel => {
	if (relatedPlayer && relatedPlayer.related && relatedPlayer.related.sports) {
		const result = relatedPlayer.related.sports.filter(
			(entity: Related) => entity.data && entity.type === ContentTypes.PLAYER && entity.provider === provider && entity.data.sport === sport,
		);
		return result[0] && result[0].data;
	}
	return {} as PlayerModel;
};

export const remapTeamFromSportsApiRelated = (relatedTeam: any, provider: string, sport: string): SportTeamModel => {
	if (relatedTeam && relatedTeam.related && relatedTeam.related.sports) {
		const result = relatedTeam.related.sports.filter(
			(entity: Related) => entity.data && entity.type === ContentTypes.TEAM && entity.provider === provider && entity.data.sport === sport,
		);
		return result[0] && result[0].data;
	}
	return {} as SportTeamModel;
};

export const remapTournamentFromRelated = (relatedTournament: any, provider: string): TournamentModel => {
	if (relatedTournament && relatedTournament.related && relatedTournament.related.sports) {
		const result = relatedTournament.related.sports.filter(
			(entity: Related) => entity.type === ContentTypes.SEASON && entity.provider === provider,
		);
		return result[0] && result[0].data && responseToTournamentModel(result[0].data.tournament);
	}

	return {} as TournamentModel;
};

export const remapCompetitionFromRelated = (relatedCompetition: any, provider: string, sport: string): CompetitionModel => {
	if (relatedCompetition && relatedCompetition.related && relatedCompetition.related.sports) {
		const result = relatedCompetition.related.sports.filter(
			(entity: Related) =>
				entity.data && entity.type === ContentTypes.COMPETITION && entity.provider === provider && entity.data.sport === sport,
		);
		return result[0] && result[0].data && responseToCompetitionModel(result[0].data);
	}

	return {} as CompetitionModel;
};

export const remapSeasonFromRelated = (relatedSeason: any, provider: string): SeasonModel => {
	if (relatedSeason && relatedSeason.related && relatedSeason.related.sports) {
		const result = relatedSeason.related.sports.filter(
			(entity: Related) => entity.type === ContentTypes.SEASON && entity.provider === provider,
		);
		return result[0] && result[0].data && responseToSeasonModel(result[0].data);
	}

	return {} as SeasonModel;
};

export const remapBasketballSeasonFromRelated = (relatedSeason: any, provider: string): BasketballSeasonModel => {
	if (relatedSeason && relatedSeason.related && relatedSeason.related.sports) {
		const result = relatedSeason.related.sports.filter(
			(entity: Related) => entity.type === ContentTypes.SEASON && entity.provider === provider,
		);
		return result[0] ? result[0].data && responseToBasketballSeasonModel(result[0].data) : ({} as BasketballSeasonModel);
	}

	return {} as BasketballSeasonModel;
};

export const remapTournamentSeasonFromRelated = (relatedSeason: any, provider: string): EntitySeasonsSelectModel => {
	if (relatedSeason && relatedSeason.related && relatedSeason.related.sports) {
		const result = relatedSeason.related.sports.filter(
			(entity: Related) => entity.type === ContentTypes.SEASON && entity.provider === provider,
		);
		if (result[0]) {
			return {
				seasonId: result[0].data.id,
				seasonName: result[0].data.name,
				tournamentName: result[0].data.tournament.name,
				tournamentId: result[0].data.tournament.id,
			} as EntitySeasonsSelectModel;
		}
	}

	return {} as EntitySeasonsSelectModel;
};

export const filterEventsByProvider = (related: Related[], provider: string, sport: string) => {
	return related && related.filter((entity: Related) => entity.provider === provider && entity.data.sport === sport);
};

export const filterEventsByProviderSingleEvent = (related: Related[], provider: string) => {
	return related && related.filter((entity: Related) => entity.provider === provider);
};

export const prefillFootballSingleEventMatchFromRelated = (selectedMatches: Related[]): MatchModel => {
	selectedMatches = filterEventsByProviderSingleEvent(selectedMatches, relatedConstants.providers.football);

	if (selectedMatches && selectedMatches.length > 0) {
		return remapMatchForSingleEventFromRelated(selectedMatches[0], relatedConstants.providers.football);
	}

	return {} as MatchModel;
};

export const prefillFootballSingleEventMatchFromRelatedV2 = (selectedMatches: Related[]): MatchModelV2 => {
	selectedMatches = filterEventsByProviderSingleEvent(selectedMatches, relatedConstants.providers.football);

	if (selectedMatches && selectedMatches.length > 0) {
		return remapMatchForSingleEventFromRelatedV2(selectedMatches[0], relatedConstants.providers.football);
	}

	return {} as MatchModelV2;
};

export const prefillGameFromRelated = (selectedGames: Related[], sport: string): GameModel => {
	selectedGames = filterEventsByProvider(selectedGames, relatedConstants.providers.sports, sport);
	if (selectedGames && selectedGames.length > 0) {
		return remapGameFromRelated(selectedGames[0], relatedConstants.providers.sports, sport);
	}

	return {} as GameModel;
};

export const prefillMatchFromRelated = (selectedGames: Related[], sport: string): GameModel => {
	selectedGames = filterEventsByProvider(selectedGames, relatedConstants.providers.sports, sport);
	if (selectedGames && selectedGames.length > 0) {
		return remapMatchFromRelated(selectedGames[0], relatedConstants.providers.sports, sport);
	}

	return {} as GameModel;
};

export const createBlock = (contentType: availableWidgetTypes, blockData: any, contentData: any, block?: BlockModel) => {
	const blockEntity = getModelByWidgetType(contentType, block ? block : ({} as BlockModel));
	if (blockEntity) {
		if (blockData) {
			return blockEntity.builder(blockData).build();
		} else {
			return getBuilderByWidgetType(contentType, contentData, block ? block : ({} as BlockModel));
		}
	}

	return null;
};
